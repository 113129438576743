<template>
    <div class="notification-window">
        <div class="control-part">
            <button class="plain-text" @click="openAdditional" v-if="userOptions.role!='demo'">
                Открыть дополнительные параметры
            </button>
            <el-collapse v-if="showAdditionalPart">
                <el-collapse-item title="Telegram">
                    <div>
                        <el-button class="button" @click="takeSecretNumber" text>{{nameTelegramButton}}</el-button>
                        <div class="text item" v-if="secretNumber">
                            <ul>
                                <li class="back-text">Зайдите в Telegram и найдите бот "Rsmartbot".</li>
                                <li class="back-text">Запустите его</li>
                                <li class="back-text">Отправьте временный личный код.</li>
                                <p class="back-text">Временный личный код: <el-button class="plain-text" @click="chainingTelegram(secretNumber)" plain>{{ secretNumber }}</el-button></p>
                            </ul>
                        </div>
                        <p v-if="nameTelegramButton == 'Отвязать'">Уведомлять
                            <el-switch 
                                v-model="telegramFlagNotification"
                                class="mt-2"
                                style="margin-left: 24px"
                                inline-prompt
                                :active-icon="Check"
                                :inactive-icon="Close"
                                :loading="loadingTelegramFlag"
                                :before-change="beforeTelegramNotificationChange"
                            />
                        </p>
                    </div>
                </el-collapse-item>
                <el-collapse-item title="Email">
                    <div>
                        <p>Уведомлять
                            <el-switch 
                                v-model="emailFlagNotification"
                                class="mt-2"
                                style="margin-left: 24px"
                                inline-prompt
                                :active-icon="Check"
                                :inactive-icon="Close"
                                :loading="loadingEmailFlag"
                                :before-change="beforeEmailNotificationChange"
                            />
                        </p>
                    </div>
                </el-collapse-item>
            </el-collapse>
            <!-- <el-space direction="vertical" v-if="showAdditionalPart">
                <el-card class="box-card" style="width: 320px">
                <template #header>
                    <div class="card-header">
                        <h3>Telegram <el-button class="button" @click="takeSecretNumber" text>{{nameTelegramButton}}</el-button></h3>
                    </div>
                </template>
                <div class="text item" v-if="secretNumber">
                    <ul>
                        <li class="back-text">Зайдите в Telegram и найдите бот "Rsmartbot".</li>
                        <li class="back-text">Запустите его</li>
                        <li class="back-text">Отправьте временный личный код.</li>
                        <p class="back-text">Временный личный код: <el-button class="plain-text" @click="chainingTelegram(secretNumber)" plain>{{ secretNumber }}</el-button></p>
                    </ul>
                </div>
                </el-card>
                asdasd
            </el-space> -->
        </div>
        <div :class="{'loader-place': notificationsLoading}">
            <Loader  v-if="notificationsLoading" /> 
                <!-- <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div> -->
            <div v-else>
                
                    <NotificationLine
                        v-for="item in notificationList" :key="item.id"
                        :text="item.message"
                        :datetime="item.timestamp"
                        :type="item.type_notification"
                    />
                    <!-- <li v-for="item in notificationList" :key="item.id">{{item.message}}</li> -->
                    <button v-if="!notificationsAdditionLoading" class="button-load-more" @click="getAdditionsNotifications(notificationList[notificationList.length-1].timestamp)">загрузить больше...</button>
                    <div :class="{'loader-place': notificationsAdditionLoading}">
                        <Loader  v-if="notificationsAdditionLoading" />
                    </div>
            </div>
        </div>
        <!-- <div class="notification-form" v-if="showAdditionalPart"> -->
            <!-- <ul>
                <li>Telegram: <button @click="takeSecretNumber">Привязать</button></li>
                <li></li>
            </ul> -->
            

        <!-- </div> -->
    </div>
</template>
<script>
import { ElMessage } from 'element-plus'
import Loader from './Loader.vue'
import axios from '../../../config-axios'
import { mapMutations, mapGetters, mapActions } from 'vuex'
import NotificationLine from './NotificationLine.vue'

export default {
    name: 'WindowNotification',
    data() {
        return {
            showAdditionalPart: false,
            secretNumber: null,
            telegramFlag: false,
            // telegramFlagNotification: false,
            loadingTelegramFlag: false,
            loadingEmailFlag: false
            // emailFlagNotification: false,
        }
    },
    props: [],
    emits: [],
    computed: {
        ...mapGetters({
            userOptions: 'getUserOptions',
            notificationsLoading: 'getNotificationWindowLoading',
            notificationsAdditionLoading: 'getNotificationWindowLoadingAddition',
            notificationList: 'getNotificationWindowList',
            telegramFlagNotification: 'getTelegramFlagNotification',
            emailFlagNotification: 'getEmailFlagNotification',
        }),
        nameTelegramButton() {
            if (this.userOptions.notification.telegram) {
                return 'Отвязать'
            } else {
                return 'Привязать'
            }
        },
    },
    components: {
        Loader,
        NotificationLine
    },
    methods: {
        ...mapActions({
            getNotifications: 'getApiNotifications',
            getAdditionsNotifications: 'getApiAdditionsNotifications'
        }),
        ...mapMutations({
            setNotificationTelegram: 'setNotificationTelegram',    
            setTelegramFlagNotification: 'setTelegramFlagNotification',
            setEmailFlagNotification: 'setEmailFlagNotification'
        }),
        openAdditional() {
            this.showAdditionalPart = !this.showAdditionalPart
        },
        chainingTelegram(secretNumber) {
            navigator.clipboard.writeText(secretNumber);
            ElMessage({
                message: 'Скопировано!',
                type: 'success',
            });
        },
        async beforeTelegramNotificationChange() {
            this.loadingTelegramFlag = true;
            axios.put('user/notifications/', { telegram: true }).then(response=>{
                console.log(response.data)
                this.loadingTelegramFlag = false;
                if (response.data.status) {
                    this.setTelegramFlagNotification(!this.telegramFlagNotification)
                    ElMessage.success('Switch success');
                } else {
                    ElMessage.error('Switch failed');
                }
            }).catch(err=>{
                this.loadingTelegramFlag = false;
                console.log(err)
                ElMessage.error('Switch failed');
            })
        },
        async beforeEmailNotificationChange() {
            this.loadingEmailFlag = true;
            axios.put('user/notifications/', { email: true }).then(response=>{
                console.log(response.data)
                this.loadingEmailFlag = false;
                if (response.data.status) {
                    this.setEmailFlagNotification(!this.emailFlagNotification)
                    ElMessage.success('Switch success');
                } else {
                    ElMessage.error('Switch failed');
                }
            }).catch(err=>{
                this.loadingEmailFlag = false;
                console.log(err)
                ElMessage.error('Switch failed');
            })
        },
        takeSecretNumber() {
            if (this.userOptions.notification.telegram) {
                axios.delete('telegram_bot/take_secret_key/').then(response => {
                    if(response.data.status) {
                        this.setNotificationTelegram(!response.data.status)
                    }
                })
            } else {
                axios.get('telegram_bot/take_secret_key/').then(response => {
                    this.secretNumber = response.data
                })
            }
        },
    },
    created() {
        this.getNotifications()
    }
}
</script>
<style noscoped lang="scss">
    .notification-window {
        .notification-form {
            border-radius: 4px;
            padding: 25px 30px;
            background: rgba(0, 0, 0, 0.078);

            button {
                cursor: pointer;
            }
        }

        .control-part {
            margin-bottom: 10px;

            button {
                cursor: pointer;
                border-radius: 4px;
                padding: 5px 10px;
                background: none;
                transition: all .2s ease;

                &:hover {
                    background: rgba(0, 0, 0, 0.078);
                }
            }
        }
        .button-load-more {
            width: 100%;
            cursor: pointer;
            background: rgba(74, 74, 74, 0.353);
            padding: 10px 0;
            border-radius: 4px;
            transition: all .3s ease;

            &:hover {
                background: rgba(74, 74, 74, 0.211);
            }

            &:active {
                background: rgba(74, 74, 74, 0.063);
            }
        }
        .loader-place {
            display: flex;
            justify-content: center;
        }
    }
</style>