<template>
<div class="background-window" @click.self="closeThisWindow">
    <div class="consumables-window" :class="{wideWinow: userOptions.role == 'developer' || userOptions.role == 'admin'}">
        
        <div class="head-window">
            <h2 class="middle-high">Расходные материалы</h2>
            <button @click="closeThisWindow">
                <img src="@/assets/imgs/crosser-black.svg" alt="">
            </button>
        </div>
        <div class="consum-table-place">
            <el-table :data="consumablesList" style="width: 100%" max-height="250">
                <el-table-column fixed prop="code" label="Код" width="150" />
                <el-table-column prop="name" label="Название" width="230" />
                <el-table-column prop="number" label="Количество" width="120" />
                <el-table-column fixed="right" label="Операции" min-width="80" v-if="userOptions.role == 'developer' || userOptions.role == 'admin'">
                    <template #default="scope">
                        <el-button
                            size="small"
                            type="primary"
                            @click="clickEdit(scope.$index, scope.row)"
                        >Изменить</el-button>
                        <el-button
                            size="small"
                            type="danger"
                            @click="deleteConsumable(scope.$index, scope.row)"
                        >Удалить</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="writing-place-consumables" v-if="showInputPlaceFlag && (userOptions.role == 'developer' || userOptions.role == 'admin')">
                <div class="fields-place">
                    <el-input v-model="tempCodeField" style="width: 150px" placeholder="Код" />
                    <el-input v-model="tempNameField" style="width: 120px" placeholder="Название" />
                    <el-input-number v-model="tempNumberField" style="width: 120px"  />
                </div>
                <div class="button-place">
                    <el-button class="button-writen-place" type="success" plain @click="saveConsumables">Сохранить</el-button>
                    <el-button class="button-writen-place" type="danger" plain @click="showInputPlaceFlag = !showInputPlaceFlag">Отменить</el-button>
                </div>
            </div>
            <el-button style="width: 100%" v-if="!showInputPlaceFlag && (userOptions.role == 'developer' || userOptions.role == 'admin')"  @click="clickAdd">
                Add Item
            </el-button>
        </div>
    </div>
</div>
</template>
<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'

export default {
    name: 'WindowConsumables',
    data() {
        return {
            showInputPlaceFlag: false,
            workRow: {
                code: '',
                name: '',
                number: 0
            },
            tempEditId: null,
            tempCodeField: '',
            tempNameField: '',
            tempNumberField: 0,
            mode: ''
        }
    },
    props: ['compressorId'],
    emits: ['closeWindow'],
    computed: {
        ...mapGetters({
            consumablesList: 'getConsumablesList',
            userOptions: 'getUserOptions',
        })
    },
    methods: {
        ...mapActions({
            postConsumable: 'postApiConsumable',
            getConsumables: 'getApiConsumables',
            putConsumable: 'putApiConsumable',
            deleteApiConsumable: 'deleteApiConsumable',
        }),
        saveConsumables() {
            if (this.mode == 'post') this.postConsumable({
                id: this.compressorId,
                data: {
                    code: this.tempCodeField,
                    name: this.tempNameField,
                    number: this.tempNumberField
                }
            });
            else this.putConsumable({
                id: this.tempEditId,
                data: {
                    code: this.tempCodeField,
                    name: this.tempNameField,
                    number: this.tempNumberField
                }
            })
            this.tempCodeField = ''
            this.tempNameField = ''
            this.tempNumberField = 0
            this.showInputPlaceFlag = !this.showInputPlaceFlag;
            this.mode = '';
        },
        deleteConsumable(index, row) {
            this.deleteApiConsumable(row);
        },
        clickEdit(index, row) {
            this.showInputPlaceFlag = true;
            this.mode = 'put';
            this.tempEditId = row.id
            this.tempCodeField = row.code;
            this.tempNameField = row.name;
            this.tempNumberField = row.number;
        },
        clickAdd() {
            this.showInputPlaceFlag = !this.showInputPlaceFlag;
            this.mode = 'post';
            this.tempCodeField = '';
            this.tempNameField = '';
            this.tempNumberField = 0;
            this.tempEditId = null;
        },
        closeThisWindow() {
            this.$emit('closeWindow');
        }
    },
    created() {
        this.getConsumables({id: this.compressorId})
    }
}
</script>
<style noscoped lang="scss">
.consumables-window {
    cursor: auto;
    // width: 40vw;
    padding: 20px 2%;
    border-radius: 4px;
    background: var(--background-white);

    &.wideWinow {
        width: 40vw;
    }

    .head-window {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        button {
            padding: 1px 2px;
            background: none;
            cursor: pointer;
        }
    }

    .consum-table-place {

    }
    .writing-place-consumables {
        display: flex;
        justify-content: space-between;

        .button-writen-place {
            margin-left: 0;
        }
    }
}
</style>