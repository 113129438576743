import axios from 'axios';
//axios.defaults.baseURL = 'https://194.62.19.154:8001/'
 axios.defaults.baseURL = 'https://my-remeza.by:8443/'
// axios.defaults.baseURL = 'http://127.0.0.1:8003/'
// axios.defaults.baseURL = 'http://127.0.0.1:8000/'
// axios.defaults.baseURL = 'http://192.168.1.149:8000/'
// axios.defaults.baseURL = 'http://shylin.ru:8000/'
// axios.defaults.headers.common = {'Authorization': `bearer ${token}`}
function getCookie(name) {
    const cookieValue = document.cookie.split('; ').find(row => row.startsWith(name + '='));
    return cookieValue ? cookieValue.split('=')[1] : null;
  }

const csrftoken = getCookie('csrftoken');
axios.defaults.headers.common = {
    'X-CSRFToken': csrftoken
}
if (localStorage.getItem('token')) {
  axios.defaults.headers.common = {
    ...axios.defaults.headers.common,
    'Authorization': `Token ${localStorage.getItem('token')}`,
  }
}
export default axios;