<template>
    <div class="notification-line">
        <div class="left-part" :class="{
                plane: type=='plane',
                error: type=='error',
                to: type=='TO'
            }">
            <div class="type-notification">{{ type }}</div>
            <div class="date-part small-text">{{ timemark }}</div>
        </div>
        <div class="message-part">{{ text }}</div>
    </div>
</template>

<script>
export default {
    name: 'NotificationLine',
    data() {
        return {}
    },
    props: ['datetime', 'type', 'text'],
    computed: {
        timemark() {
            let date = new Date(this.datetime);
            return this.datetime.slice(0, 10) + ' ' + this.datetime.slice(11, 16)
        },
    }
}
</script>
<style scoped lang="scss">
.notification-line {
    display: flex;
    padding: 5px 10px;
    // margin: 5px 0;
    // .date-part {
        
    // }

    .left-part {
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 4px;
    }
    .plane {
        background: rgb(142, 141, 141);
    }
    .error {
        background: rgb(255, 112, 112);
    }
    .to {
        background: rgb(227, 255, 117);
    }
    .message-part {
        width: 70%;
        padding: 0 10px;
        background: rgba(142, 141, 141, 0.411);
        border-radius: 4px;
    }
}
</style>