<template>
    <div class="background-window" @click.self="closeWindow">
        <div class="window-param">
            <div class="head-window">
                <h2 class="name-window-param middle-high">{{nameWinow}}</h2>
                <button @click="closeWindow">
                    <img src="@/assets/imgs/crosser-black.svg" alt="">
                </button>
            </div>
            <div class="body-window">
                <div class="input-form-compressor" name-input="">
                    <!-- <input class="name-compressor-input"  type="text" v-model="name"> -->
                    <p>
                        Наименование компрессора: 
                        <el-input v-if="userOptions.role == 'developer' || userOptions.role == 'admin' || userOptions.role == 'dealer'" v-model="name" class="plain-text" size="small" placeholder="name" />
                        <strong v-else >{{ name }}</strong>
                    </p>
                    <!-- <label class="plain-text" :class="{ active: name }">Наименование компрессора</label> -->
                </div>
                <div class="input-form-compressor" name-input="">
                    <p class="plain-text">Тип: <strong>{{type_controller}}</strong></p>
                </div>
                <div class="input-form-compressor" name-input="">
                    <p class="plain-text">MAC адрес: <strong>{{mac}}</strong></p>
                </div>
                <div class="input-form-compressor" name-input="">
                    <p class="plain-text">Дата регистрации: <strong>{{date.slice(0, 19)}}</strong></p>
                </div>
                <div class="input-form-compressor" name-input="">
                    <p class="plain-text">
                        MAC адрес модема: <strong>{{modem_mac}}</strong>
                        <button
                            v-if="userOptions.role == 'developer' || userOptions.role == 'admin'"
                            class="button-go-to-modem"
                            @click="goToModem"
                        >&raquo;</button>
                    </p>
                </div>
                <div v-if="!wifi_mode" class="input-form-compressor" name-input="">
                    <p class="plain-text">
                        Оператор сим: <strong>{{sim_operator}}</strong>
                    </p>
                </div>
                <div v-if="!wifi_mode" class="input-form-compressor" name-input="">
                    <p class="plain-text">
                        Сигнал сим: <strong>{{sim_quality}}%</strong>
                    </p>
                </div>                
                <div v-if="wifi_mode" class="input-form-compressor" name-input="">
                    <p class="plain-text">
                        WiFi точка: <strong>{{wifi_ssid}}</strong>
                    </p>
                </div>
                <div v-if="wifi_mode" class="input-form-compressor" name-input="">
                    <p class="plain-text">
                        Уровень сигнал: <strong>{{wifiSignal}}%</strong>
                    </p>
                </div>
                <div class="input-form-compressor" name-input="">
                    <p class="plain-text">
                        Руководство пользователя:
                        <el-button 
                            v-if="userOptions.role != 'developer' && userOptions.role != 'admin'"
                            @click="downloadManual"
                            size="small"
                            type="primary"
                            style="margin-left: 5px"
                            plain
                        >download ⤓</el-button>
                    </p>
                    <div class="uploading-downloading-form" v-if="userOptions.role == 'developer' || userOptions.role == 'admin'">
                        <input type="file" ref="myFiles" name="file">
                        <el-button size="small" type="success" @click="uploadFile" plain>upload ↥</el-button>
                        <el-button size="small" type="primary" @click="downloadManual" style="margin-left: 0" plain>download ⤓</el-button>
                    </div>
                </div>
                <div class="input-form-compressor" name-input="" v-if="userOptions.role == 'developer' || userOptions.role == 'admin'">
                    <p class="plain-text">Название дилера:
                        <el-select
                            v-model="dealerId"
                            placeholder="Select"
                            size="small"
                        >
                            <el-option
                                v-for="item in dealerList"
                                :key="item.label"
                                :label="item.label"
                                :value="item.id"
                            />
                        </el-select>
                    </p>
                    <!-- <label class="plain-text" :class="{ active: dealerId }">Название дилера</label> -->
                </div>
                <div class="input-form-compressor" name-input="" v-if="userOptions.role == 'developer' || userOptions.role == 'admin' || userOptions.role == 'dealer'">
                    <p class="plain-text">Название компании:
                        <el-select
                            v-model="companyId"
                            placeholder="Select"
                            size="small"
                        >
                            <!-- <el-option
                                v-for="item in companySmartList"
                                :key="item.label"
                                :label="(userOptions.role == 'developer' || userOptions.role == 'admin')?(item.label+' ('+item.dealer+')'):(item.label)"
                                :value="item.id"
                            /> -->
                            <el-option
                                v-for="item in companySmartList"
                                :key="item.label"
                                :label="item.label"
                                :value="item.id"
                            />
                        </el-select>
                    </p>
                    <!-- <label class="plain-text" :class="{ active: companyId }">Название компании</label> -->
                </div>
                <div v-if="userOptions.role == 'developer' || userOptions.role == 'admin'" class="input-form-compressor" name-input="">
                    <button class="button-form-compressor plain-text red-color" @click="dialogDeleteCompressorVisible = true">Удалить компрессор</button>
                </div>
                <div class="button-place">
                    <!-- <button class="button-form-compressor plain-text red-color" @click="closeWindow">Отменить</button> -->
                    <button class="button-form-compressor plain-text blue-color" @click="postData">Применить</button>
                </div>
            </div>
        </div>
        
        <el-dialog v-model="dialogDeleteCompressorVisible" title="Удаление компрессора" width="500">
            <el-input v-model="tempDeleteLine" placeholder='Напишите "удалить", чтобы продолжить.' />
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="cancelDelete">Отменить</el-button>
                    <el-button type="danger" :disabled="tempDeleteLine != 'удалить'" :loading="deleteLoading" @click="deleteCompressor">
                    Удалить
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    name: "WindowParam",
    emits: ['closeWindow', 'goToModem'],
    props: [
        'id', 'mac', 'nameCompressor', 'date', 'company_id', 'dealer_id', 'type_controller', 'modem_mac', 'modem_id', 'sim_quality', 'sim_operator',
        'wifi_mode', 'wifi_ssid', 'wifi_rssi'
    ],
    data() {
        return {
            name: '',
            nameWinow: '',
            companyName: '',
            companyId: 0,
            dealerId: 0,
            dialogDeleteCompressorVisible: false,
            deleteLoading: false,
            tempDeleteLine: '',
            fileManualUploading: []
        }
    },
    watch: {
        dialogDeleteCompressorVisible(newFlag) {
            if (newFlag===false) {
                this.tempDeleteLine = '';
            }
        },
        dealerId(newValue) {
            if (newValue == 0) {
                this.companyId = 0
            }
        }
    },
    computed: {
        nameWindow() {
            if (this.nameCompressor) {
                return this.nameCompressor
            } else {
                return this.mac
            }
        },
        companySmartList() {
            if (this.userOptions.role == 'admin' || this.userOptions.role == 'developer') {
                return this.companyList.filter(el=> {
                    if (el.dealer_id == this.dealerId || el.id == 0) {
                        return true
                    } else {
                        return false
                    }
                })
            } else {
                return this.companyList
            }
        },
        wifiSignal() {
            if (this.wifi_rssi>=-50) {
                return 100
            }
            if (this.wifi_rssi<=-100) {
                return 0
            }
            return ((this.wifi_rssi-100)/50)*100
        },
        ...mapGetters({
            userOptions: 'getUserOptions',
            companyList: 'getCompanyList',
            dealerList: 'getDealerList'
        })
    },
    methods: {
        ...mapActions({
            setCompressorSettings: 'putCompressorSettings',
            deleteApiCompressor: 'deleteCompressorSettings',
            getCompanyList: 'getApiCompanyList',
            getDealerList: 'getApiDealerList',
            uploadManual: 'postUploadManual',
            apiDownloadManual: 'getDownloadManual',
        }),
        cancelDelete() {
            this.dialogDeleteCompressorVisible = false;
        },
        async deleteCompressor() {
            // alert('Delete');
            this.deleteLoading = true;
            await this.deleteApiCompressor(this.id);
            this.deleteLoading = false;
            this.dialogDeleteCompressorVisible = false;
        },
        closeWindow() {
            this.$emit('closeWindow')
        },
        changeDealerClick(opt) {
            // alert('work')
            // console.log(opt)
            this.companyId = 0
        },
        postData() {
            let options = {
                name: this.name,
                id: this.id,
                dealer_id: this.dealerId,
                company_id: this.companyId
            }
            console.log(options)
            this.setCompressorSettings(options)
            this.closeWindow()
        },
        goToModem() {
            this.$emit('goToModem', this.modem_id)
        },
        uploadFile() {
            if (this.$refs.myFiles.files.length == 1) {
                const formData = new FormData();
                formData.append('bytes', this.$refs.myFiles.files[0]);
                this.uploadManual({
                    id: this.id,
                    data: formData
                })
            }
        },
        downloadManual() {
            this.apiDownloadManual({id:this.id})
        }
    },
    created() {
        this.name = this.nameCompressor
        this.companyId = this.company_id==null?0:this.company_id
        this.dealerId = this.dealer_id==null?0:this.dealer_id
        if (this.nameCompressor) {
            this.nameWinow = this.nameCompressor
        } else {
            this.nameWinow = this.mac
        }
        if (this.userOptions.role != 'user' && this.userOptions.role != 'demo') {
            this.getCompanyList();
        }
        if (this.userOptions.role == 'admin' || this.userOptions.role == 'developer') {
            this.getDealerList();
        }
    }
}
</script>
<style noscoped lang="scss">
.background-window{
    position: absolute;
    z-index: 2;
    top:0;
    left:0;
    background: rgba(0, 0, 0, 0.295);
    width: 100vw;
    height: 100vh;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.window-param{
    background: var(--background-white, #FCFCFC);
    cursor: auto;
    width: 398px;
    border-radius: 4px;
    padding: 12px 9px;
    display: flex;
    flex-direction: column;

    .head-window {
        display: flex;
        justify-content: space-between;

        button {
            padding: 1px 2px;
            background: none;
            cursor: pointer;
        }
    }
    .input-form-compressor {
        position: relative;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        
        .uploading-downloading-form {
            display: flex;
            flex-direction: column;
            padding: 10px 10px;
            border: 1px solid var(--gray-dark-color);
            border-radius: 4px;
            
            input {
                margin-bottom: 5px;
            }

        }
        
        // input {
        .name-compressor-input{
            padding: 3px 6px;
            width: 343px;
            min-width: 285px;
            padding: 3px 6px;
            border-radius: 4px;
            border: 1px solid var(--gray-dark-color, #949494);
        }

        label {
            order: -1;
            pointer-events: none;
            margin: 1px 1px;
            padding: 0 6px;
            // transform: translateY(20px);
            background: var(--background-white);
            transition: all .2s ease;
            left: 3px;
            border-radius: 4px;
            color: var(--gray-dark-color, #949494);
            position: absolute;
            // left: 6px;
            white-space: nowrap;
        }
        input:focus ~ label {
            // bottom: 100%;
            transform: translateY(-14px);
        }
        .active {
            transform: translateY(-14px);
        }
        
    }
    .body-window {
        align-self: center;
    }
    .button-place {
        margin-top: 26px;
        // display: flex;
        // flex-direction: column;

        button {
            transition: all .2s ease;
            box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
        }
        button:hover {
            box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2);
        }
        button:active {
            box-shadow: none;
        }
    }
    .button-form-compressor:first-child {
        margin-bottom: 11px;
    }
    .button-form-compressor {
        width: 100%;
        // padding: 5px 0px;
        // width: 343px;
        color: #FFF;
        cursor: pointer;
        // box-sizing: border-box;
        border-radius: 8px;
        padding: 6px 0px;
        transition: all ease .3s;
    }
    .red-color {
            background: var(--red-color, #F97676); 
        }
    .blue-color {
        background: var(--background-blue, #3F51B5);
    }
    .button-go-to-modem {
        margin-left: 10px;
        padding: 0px 10px;
        background: var(--background-white, #FCFCFCFF);
        border: 1px solid var(--gray-colo, #EDEDEDFF);
        border-radius: 4px;

        &:hover {
            background: rgb(223, 223, 223);
        }
        &:active {
            background: rgb(200, 200, 200);
        }
    }
}
@media screen and (max-width: 420px) {
    .window-param{
        width: 361px;
        .input-form-compressor {
            input {
                width: 297px;
            }
        }
        .button-form-compressor {
            width: 297px;
        }
        .middle-high {
            font-family: Lato;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}

</style>