<template>
    <RouterView />
</template>
  
  <script>
  
  export default {
    name: 'PreApp',
    data() {
      return {
      }
    },
    components: {
    },
    watch: {
    },
    computed: {
    },
    methods: {
    },
    created() {
    }
  }
  </script>
  
  <style lang="scss">
  </style>
  