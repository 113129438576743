import { createWebHistory, createRouter } from 'vue-router'

import AppView from './App.vue'
import DemoView from './components/TestPage/TestDashboard.vue'

const routes = [
  { path: '/', component: AppView },
  { path: '/test', component: DemoView },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router