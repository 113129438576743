<template>
    <div class="board-place">
        <div class="work-line">
            <div class="work-part">
                <img src="@/assets/imgs/icon-rsmart.svg" class="wide-type " alt="">
                <img
                    src="@/assets/imgs/menu-line.svg"
                    style="cursor: pointer"
                    v-if="!windowMenu"
                    class="narrow-type"
                    alt=""
                    @click="clickMenuLine"
                >
                <img 
                    src="@/assets/imgs/crosser.svg"
                    style="cursor: pointer"
                    v-if="windowMenu"
                    class="narrow-type"
                    alt=""
                    @click="closeAllWindow"
                >
                <button
                    class="middle-high"
                    :class="{
                        choosen: choosenPage == 'dashboard'
                    }"
                    @click="clickDashboard"
                >Dashboard</button>
                <button
                    class="middle-high"
                    :class="{
                        choosen: choosenPage == 'company'
                    }"
                    v-if="userData.role == 'developer' || userData.role == 'dealer' || userData.role == 'admin'"
                    @click="clickCompany"
                >Company</button>
                <button
                    class="middle-high"
                    :class="{
                        choosen: choosenPage == 'modem'
                    }"
                    v-if="userData.role == 'developer' || userData.role == 'admin'"
                    @click="clickModem"
                >Modem</button>
            </div>
            <div class="high-board-right-side">
                <div class="notification-button" @click="clickNotification">
                    <img src="@/assets/imgs/notification-icon.svg" alt="ring">
                </div>
                <div class="user-part" @click="clickUserLine">
                    <img src="@/assets/imgs/profile.svg" alt="">
                    <p class="plain-text">{{userData.username}}</p>
                    <img src="@/assets/imgs/arrow-down.svg" alt="" class="arrow-img" :class="{choosen: windowUser}">
                </div>
            </div>
        </div>
        <el-drawer
            v-if="windowNotificationFlag"
            v-model="windowNotificationFlag"
            title="Уведомления"
            :size="sizeNotification"
            direction="rtl"
            :before-close="handleCloseNotification"
        >
            <WindowNotification
                :userData="userData"
            />
        </el-drawer>
        <el-dialog v-model="dialogFormDeleteVisible" title="Удаление аккаунта" width="500">
            <el-input v-model="tempFormDelete" placeholder='Напишите "удалить", чтобы продолжить.' />
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="cancelDelete">Отменить</el-button>
                    <el-button type="danger" :disabled="tempFormDelete != 'удалить'" @click="deleteAccount">
                    Удалить
                    </el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog v-model="dialogFormChangePasswordVisible" title="Сменить пароль" width="500">
            <el-input v-model="oldPassword" placeholder='Старый пароль' type="password" />
            <el-input v-model="newPassword" placeholder='Новый пароль' type="password" style="margin-top: 10px" />
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="cancelChangePassword">Отменить</el-button>
                    <el-button type="primary" @click="changePassword">
                    Изменить
                    </el-button>
                </div>
            </template>
        </el-dialog>
        <Transition name="fade" mode="out-in">
            <div class="shadow-place" v-if="windowShadow" @click.self="closeAllWindow">
                <div class="user-window" v-if="windowUser">
                    <p class="plain-text">Приветствую, {{userData.username}}</p>
                    <p class="plain-text">Роль: {{userData.role}}</p>
                    <button class="button-menu blue-color" @click="dialogFormChangePasswordVisible = true" v-if="userData.role!='demo'">Сменить пароль</button>
                    <button class="button-menu red-color" @click="dialogFormDeleteVisible = true" v-if="userData.role!='demo'">Удалить аккаунт</button>
                    <button @click="logOut">
                        <img src="@/assets/imgs/exit.svg" alt="">
                        Выйти
                    </button>
                </div>
                <div class="menu-window" v-if="windowMenu">
                    <!-- hiden place with button page for smal window -->
                    <button
                        class="plain-text"
                        @click="clickDashboard"
                        :class="{
                            choosen: choosenPage == 'dashboard'
                        }"
                    >Dashboard</button>
                    <button
                        class="plain-text"
                        @click="clickCompany"
                        v-if="userData.role == 'developer' || userData.role == 'dealer' || userData.role == 'admin'"
                        :class="{
                            choosen: choosenPage == 'company'
                        }"
                    >Company</button>
                    <button
                        class="plain-text"
                        v-if="userData.role == 'developer' || userData.role == 'admin'"
                        @click="clickModem"
                        :class="{
                            choosen: choosenPage == 'modem'
                        }"
                    >Modem</button>
                </div>
            </div>
        </Transition>
    </div>
</template>
<script>
import WindowNotification from './WindowNotification.vue'

import { ElMessage } from 'element-plus'

import axios from '../../../config-axios'

export default {
    name: 'HighBoard',
    data() {
        return {
            // choosenPage: 'dashboard',
            windowShadow: false,
            windowUser: false,
            windowMenu: false,
            windowNotificationFlag: false,
            tempFormDelete: '',
            dialogFormDeleteVisible: false,
            oldPassword: '',
            newPassword: '',
            dialogFormChangePasswordVisible: false
        }
    },
    components: {
        WindowNotification
    },
    computed: {
        sizeNotification() {
            return window.innerWidth*0.3<350?'350px':window.innerWidth*0.3+'px'
        }
    },
    props: ["choosenPage", 'userData'],
    emits: ['logOut', 'clickCompany', 'clickDashboard', 'clickModem'],
    methods: {
        cancelDelete() {
            this.tempFormDelete = '';
            this.dialogFormDeleteVisible = false;
        },
        deleteAccount() {
            axios.delete('user/changepassword/').then(response => {
                if (response.data.status) {                    
                    this.dialogFormDeleteVisible = false;
                    this.tempFormDelete = '';
                    this.$emit('logOut')
                } else {
                    ElMessage.error(response.data.text)
                }
            })
        },
        cancelChangePassword() {
            
            this.oldPassword = '';
            this.newPassword = '';
            this.dialogFormChangePasswordVisible = false;
        },
        changePassword() {
            axios.put('user/changepassword/', {
                old_password: this.oldPassword,
                new_password: this.newPassword
            }).then(response => {
                if (response.data.status) {
                    this.oldPassword = '';
                    this.newPassword = '';
                    this.dialogFormChangePasswordVisible = false;
                } else {
                    ElMessage.error(response.data.text)
                }
            })
        },
        clickCompany() {
            // this.choosenPage = 'company'
            this.closeAllWindow()
            this.$emit('clickCompany')
        },
        clickDashboard() {
            // this.choosenPage = 'dashboard'
            this.closeAllWindow()
            this.$emit('clickDashboard')
        },
        clickModem() {
            // this.choosenPage = 'modem'
            this.closeAllWindow()
            this.$emit('clickModem')
        },
        logOut() {
            this.$emit('logOut')
        },
        clickUserLine() {
            if (this.windowUser) {
                this.windowShadow = false;
            } else {
                this.windowShadow = true;
            }
            
            this.windowUser = !this.windowUser;
            this.windowMenu = false;
            this.windowNotificationFlag = false;
        },
        clickMenuLine() {
            this.windowShadow = true;
            this.windowMenu = !this.windowMenu;
            this.windowUser = false;
            this.windowNotificationFlag = false;
        },
        clickNotification() {
            console.log(this.userData)
            if (this.windowNotificationFlag) {
                this.windowShadow = false;
            } else {
                this.windowShadow = true;
            }
            
            this.windowMenu = false;
            this.windowUser = false;
            this.windowNotificationFlag = !this.windowNotificationFlag;

        },
        handleCloseNotification(done) {
            // ElMessageBox.confirm('Are you sure you want to close this?').then(() => {
            //     done()
            // })
            // .catch(() => {
            // // catch error
            // })
            this.closeAllWindow()
            done()
        },
        closeAllWindow() {
            this.windowShadow = false;
            this.windowUser = false;
            this.windowMenu = false;
            this.windowNotificationFlag = false;
        }
    }
}
</script>
<style noscoped lang="scss">
.wide-type {
    display: block;
}
.narrow-type {
    display: none;
}
.board-place{    
    background: #3F51B5;
    box-shadow: 0px 4px 18px 7px rgba(0, 0, 0, 0.45);
    width: 100vw;
    height: 7vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .shadow-place {
        z-index: 2;
        right: 0;
        top: 100%;
        position: absolute;
        background: rgba(0, 0, 0, 0.17);
        width: 100vw;
        height: 93vh;
        cursor: pointer;

        .user-window {
            border-radius: 4px;
            position: absolute;
            cursor: auto;
            width: 204px;
            // height: 160px;
            background: var(--background-white);
            top: 5px;
            right: 5px;
            padding: 20px 22px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            

            button {
                background: none;
                cursor: pointer;
                margin: 5px 0;
                display: flex;
                align-items: center;

                img {
                    margin-right: 14px;
                }
            }

            .button-menu {
                color: #FFF;
                border-radius: 4px;
                padding: 5px 10px;
                display:table-cell;
                vertical-align: middle;
                transition: all ease .3s;
            }
            .red-color {
                background: var(--red-color, #F97676); 

                &:hover {
                    background: #f88383
                }
            }
            .blue-color {
                background: var(--background-blue, #3F51B5);

                &:hover {
                    background: #3f51b5c7;
                }
            }
        }

        .menu-window {
            position: absolute;
            cursor: auto;
            left: 5px;
            top: 5px;
            width: 123px;
            height: 100px;
            background: var(--background-blue);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 9px 0;

            button {
                background: none;
                color: var(--gray-dark-color);
                cursor: pointer;
            }

            .choosen {
                color: var(--background-white);
            }

        }
    }
}
.work-line{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .work-part {
        display: flex;
        margin-left:15px;
        img {
            margin-right: 2.5vw;
        }
        button {
            margin-right: 1vw;
            color: var(--gray-dark-color);
            background: none;
            cursor: pointer;
        }

        .choosen {
            color: var(--background-white);
        }
    }
    .high-board-right-side {
        display: flex;

        .notification-button {
            display: flex;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            height: 35px;
            width: 35px;
            transition: all .2s ease;
            border-radius: 50%;

            &:hover {
                background: rgba(252, 252, 252, 0.118);
            }

            &:active {
                background: rgba(252, 252, 252, 0.194);
            }
        }

        .user-part {
            cursor: pointer;
            margin-right: 33px;
            display: inline-flex;
            color: var(--background-white);
            align-items: center;
            gap: 10px;
            flex-shrink: 0;

            


            .arrow-img {
                transition: rotate .4s ease;
            }

            .choosen {
                rotate: 180deg;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .work-part {
        button {
            display: none;
        }
        
        img {
            src: url('@/assets/imgs/menu-line.svg');
        }
    }
    .wide-type {
        display: none;
    }
    .narrow-type {
        display: block;
    }
}
</style>